<template>
  <Flourish v-if="flourish" :color="flourishColor" :class="ui?.flourish">{{ flourish }}</Flourish>
  <component
    :is="type"
    v-bind="$attrs"
    class="heading"
    :class="
      $cn(
        `text-heading`,
        {
          'mt-0.5': flourish && ['h1', 'h2'].includes(type),
          'mt-1.5': flourish && ['h3', 'h4', 'h5', 'h6'].includes(type),
          'text-center': centered,
          'text-4xl font-semibold lg:text-5xl': variant === 'pageTitle',
          'text-3xl font-semibold lg:text-4xl': variant === 'h1',
          'text-2xl font-semibold lg:text-3xl': variant === 'h2',
          'text-xl font-semibold lg:text-2xl': variant === 'h3',
          'text-lg font-bold lg:text-xl': variant === 'h4',
          'text-base font-bold lg:text-lg': variant === 'h5' || variant === 'h6',
          'text-base font-bold': variant === 'p',
        },
        ui?.base
      )
    "
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import type { FlourishColor } from "./Flourish.vue";
import type { ClassValue } from "clsx";

type Heading = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

const props = defineProps<{
  type: Heading;
  variant?: Heading | "pageTitle" | "p";
  centered?: boolean;
  flourish?: string;
  flourishColor?: FlourishColor;
  ui?: Partial<{
    flourish: ClassValue;
    base: ClassValue;
  }>;
}>();

const variant = props.variant || props.type;
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style lang="postcss">
.heading {
  span:not(.unstyled) {
    @apply font-light;
  }
}
</style>
